import { useEffect, useRef, useState } from "react";
import zahnVideoLaptop from "../../../assets/videos/zahn_edit_16-9.mp4";
import zahnVideoTablet from "../../../assets/videos/zahn_edit_4-5.mp4";
import zahnVideoMobile from "../../../assets/videos/zahn_edit_9-16.mp4";

export const ZahnVideo = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Trigger when at least 50% of the video is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsPlaying(true);
          videoRef.current?.play();
        } else {
          setIsPlaying(false);
          videoRef.current?.pause();
        }
      });
    }, options);

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);

  const screenWidth = window.innerWidth;
  let videoSource;

  if (screenWidth < 768) {
    videoSource = zahnVideoMobile;
  } else if (screenWidth >= 768 && screenWidth < 1024) {
    videoSource = zahnVideoTablet;
  } else {
    videoSource = zahnVideoLaptop;
  }

  return (
    <div className="h-11/12 mb-[50px] lg:mb-[198px]">
      <video
        ref={videoRef}
        controls={false}
        autoPlay={isPlaying}
        loop
        muted
        playsInline
        className="min-h-screen"
      >
        <source src={videoSource} type="video/mp4" />
      </video>
    </div>
  );
};
