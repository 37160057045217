import CookieConsent from "react-cookie-consent";
import cookiePdf from "../../assets/documents/cookies.pdf";

export const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      enableDeclineButton
      declineButtonText="Decline all cookies"
      declineButtonStyle={{
        backgroundColor: "#000000",
        color: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "poppins",
      }}
      flipButtons
      buttonText="Accept all cookies"
      cookieName="cookieConsent"
      style={{ background: "#751aff", padding: 4 }}
      buttonStyle={{
        color: "#000000",
        backgroundColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "poppins",
      }}
      expires={365}
    >
      This website uses cookies to enhance the user experience.
      <span style={{ fontSize: "10px" }}>
        {" "}
        By accepting, you agree to ZAHNs{" "}
        <a href={cookiePdf} target="_blank" rel="noreferrer">
          <u> Cookie Policy</u>
        </a>{" "}
      </span>
    </CookieConsent>
  );
};
