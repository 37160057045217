import { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import animationImage from "../../assets/images/animated-logo.webp";
import "../../styles/style.css";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const messageArray = [
  "Coming to you soon!",
  "Check out our BETA testing questionnaire!",
  "Sign up to hear when we launch!",
  "Launching in London first",
  "Follow our instagram now! @zahnapp.ldn",
  "Read our founder story in the footer",
  "Get involved now and sign up to our newsletter",
  "Feeling unsafe in London? Sign up today",
  "ZAHN: Built for your safety",
];

export default function Animation() {
  const animatedImageWrapperContainer = useRef();
  const circularImage = useRef();
  const outerCircle = useRef();
  const middleCircle = useRef();
  const innerCircle = useRef();
  const whiteCircle = useRef();
  const msgWrapper = useRef();
  const subtitle = useRef();

  let outerCircleScale = 1.05,
    middleCircleScale,
    innerCircleScale,
    messageInterval,
    messageIndex = 0;

  useGSAP(
    () => {
      // setting up for animation
      setSvgAtCenter();
      getScaleValues();

      const whiteCircleStrokeLength = Math.floor(
        whiteCircle.current.getTotalLength(),
      );
      whiteCircle.current.style.strokeDasharray = whiteCircleStrokeLength;
      whiteCircle.current.style.strokeDashoffset = whiteCircleStrokeLength;

      gsap.set(".outer-circle", {
        svgOrigin: "588 529",
        scale: 0,
      });
      gsap.set(".middle-circle", {
        svgOrigin: "585 529",
        scale: 0,
      });
      gsap.set(".inner-circle", {
        svgOrigin: "590 529",
        scale: 0,
      });
      gsap.set(".green-circle", {
        svgOrigin: "585 529",
        scale: 0,
      });
      gsap.set(".notification-icon", {
        transformOrigin: "center bottom",
        scale: 0,
        y: 82,
      });
      gsap.set(".white-arrow", {
        transformOrigin: "right bottom",
        scale: 0,
      });
      gsap.set(".msg-wrapper", {
        transformOrigin: "center center",
        scale: 0,
      });
      gsap.set(".img-wrapper", {
        x: -20,
        autoAlpha: 0,
      });
      gsap.set(".logo-img", {
        scale: 0,
      });
      gsap.set(".title", {
        y: -20,
        autoAlpha: 0,
      });
      gsap.set(".subtitle", {
        y: 20,
        autoAlpha: 0,
      });

      // functions

      function showMessageContents() {
        const tl = gsap.timeline({ paused: true });
        tl.to(".msg-wrapper", { scaleY: 0.08, duration: 0.1 }, "<")
          .to(
            ".msg-wrapper",
            { scaleX: 1, duration: 0.5, ease: "power1.in" },
            "<.1",
          )
          .to(".msg-wrapper", {
            scaleY: 1,
            borderRadius: 40,
            duration: 0.3,
          })
          .to(".title", { y: 0, autoAlpha: 1, duration: 0.2 })
          .to(".subtitle", { y: 0, autoAlpha: 1, duration: 0.2 }, "<")
          .to(".img-wrapper", { x: 0, autoAlpha: 1, duration: 0.2 }, "<")
          .to(".logo-img", { scale: 1, duration: 0.2 });

        return tl;
      }

      function startShowingMessage() {
        messageInterval = setInterval(updateMessageAnimation, 2500);
      }

      function stopShowingMessage() {
        clearInterval(messageInterval);
      }

      function outerPulsing() {
        const tl = gsap.timeline({
          paused: true,
          repeat: -1,
          ease: "none",
        });
        tl.fromTo(
          ".outer-circle",
          { scale: 0 },
          { scale: outerCircleScale, duration: 4.5 },
        ).to(".outer-circle", { autoAlpha: 0, duration: 1.2 }, ">-1.2");
        return tl;
      }

      function middlePulsing() {
        const tl = gsap.timeline({
          paused: true,
          delay: 1.5,
          repeat: -1,
          ease: "none",
        });
        tl.fromTo(
          ".middle-circle",
          { scale: 0 },
          { scale: middleCircleScale, duration: 4.5 },
        ).to(".middle-circle", { autoAlpha: 0, duration: 1.2 }, ">-1.2");
        return tl;
      }

      function innerPulsing() {
        const tl = gsap.timeline({
          paused: true,
          delay: 3,
          repeat: -1,
          ease: "none",
        });
        tl.fromTo(
          ".inner-circle",
          { scale: 0 },
          { scale: innerCircleScale, duration: 4.5 },
        ).to(".inner-circle", { autoAlpha: 0, duration: 1.2 }, ">-1.2");
        return tl;
      }

      function updateMessageAnimation() {
        gsap.to(".subtitle", {
          autoAlpha: 0,
          duration: 0.2,
          onComplete: () => updateMessage(),
        });
        gsap.to(".subtitle", { y: 10, duration: 0, delay: 0.2 });
        gsap.to(".subtitle", {
          y: 0,
          autoAlpha: 1,
          duration: 0.3,
          delay: 0.2,
        });
      }

      const outerTimeline = outerPulsing();
      const middleTimeline = middlePulsing();
      const innerTimeline = innerPulsing();
      const showMessageTimeline = showMessageContents();

      // Timeline

      let mainTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: animatedImageWrapperContainer.current,
          start: "clamp(center+=20px bottom)",
          end: "clamp(center+=20px center+=50px)",
          scrub: true,
          onLeave: () => {
            showMessageTimeline.play();

            outerTimeline.play();
            middleTimeline.play();
            innerTimeline.play();

            startShowingMessage();
          },
          onEnterBack: () => {
            showMessageTimeline.reverse();

            outerTimeline.pause();
            middleTimeline.pause();
            innerTimeline.pause();
            gsap.to(".outer-circle, .middle-circle, .inner-circle", {
              scale: 1,
              autoAlpha: 1,
              duration: 0.4,
            });

            stopShowingMessage();
          },
          onLeaveBack: () => {
            gsap.to(".outer-circle, .middle-circle, .inner-circle", {
              scale: 0,
              duration: 0.4,
            });
          },
        },
      });

      mainTimeline
        .to(".green-circle", { scale: 1, duration: 0.5 })
        .to(".inner-circle", { scale: 1, duration: 3 }, "<")
        .to(".white-circle", { strokeDashoffset: 0, duration: 1 }, "<+1")
        .to(".middle-circle", { scale: 1, duration: 3 }, "<")
        .to(".outer-circle", { scale: 1, duration: 3 }, "<+1")
        .to(".notification-icon", { scale: 1, y: 0, duration: 3 }, "<")
        .to(".white-arrow", { scale: 1, duration: 1 }, "<+2");
    },
    { scope: animatedImageWrapperContainer },
  );

  function setSvgAtCenter() {
    const bbox = circularImage.current.getBBox();
    const viewBox = circularImage.current.getAttribute("viewBox").split(" ");

    const cx = parseFloat(viewBox[0]) + parseFloat(viewBox[2]) / 2;
    const x = Math.round(cx - bbox.x - bbox.width / 2);

    gsap.set(".outer-layer", { x: x });
  }

  function setMessageWrapperMinHeight() {
    msgWrapper.current.style.minHeight = "0px";

    const msgWrapperClone = msgWrapper.current.cloneNode(true);
    msgWrapperClone.classList.add("msg-wrapper-clone");
    animatedImageWrapperContainer.current.appendChild(msgWrapperClone);
    const subtitleClone = msgWrapperClone.querySelector(".subtitle");

    const msgWrapperCloneHeightArray = messageArray.map((message) => {
      subtitleClone.textContent = message;
      return msgWrapperClone.offsetHeight;
    });

    animatedImageWrapperContainer.current.removeChild(msgWrapperClone);

    const cloneMaxHeight = Math.max(...msgWrapperCloneHeightArray);

    msgWrapper.current.style.minHeight = `${cloneMaxHeight}px`;
  }

  function getScaleValues() {
    const outerWidth = outerCircle.current.getBBox().width * outerCircleScale;
    middleCircleScale = (
      outerWidth / middleCircle.current.getBBox().width
    ).toFixed(3);
    innerCircleScale = (
      outerWidth / innerCircle.current.getBBox().width
    ).toFixed(3);
  }

  function updateMessage() {
    if (messageIndex === messageArray.length) {
      messageIndex = 0;
    }
    subtitle.current.textContent = messageArray[messageIndex];
    messageIndex++;
  }

  useEffect(() => {
    updateMessage();
    setMessageWrapperMinHeight();
  });

  return (
    <>
      <article>
        <div
          ref={animatedImageWrapperContainer}
          className="circular-image-wrapper"
        >
          <svg
            className="animated-circular-image"
            ref={circularImage}
            xmlns="https://www.w3.org/2000/svg"
            xmlnsXlink="https://www.w3.org/1999/xlink"
            viewBox="0 0 1078.05 1007.2"
          >
            <defs>
              <style>
                {`  .cls-1,
                 .cls-17,
                 .cls-20 {
                     fill: none;
                 }
                 .cls-2 {
                     isolation: isolate;
                 }

                 .cls-5 {
                     mix-blend-mode: multiply;
                 }
                 .cls-6 {
                     clip-path: url(#clip-path-3);
                 }
                 .cls-7 {
                     clip-path: url(#clip-path-4);
                 }
                 .cls-8 {
                     fill: url(#linear-gradient);
                 }
                 .cls-9 {
                     mix-blend-mode: overlay;
                 }
                 .cls-10 {
                     clip-path: url(#clip-path-5);
                 }
                 .cls-11 {
                     clip-path: url(#clip-path-6);
                 }
                 .cls-12 {
                     fill: url(#linear-gradient-2);
                 }
                 .cls-13 {
                     clip-path: url(#clip-path-7);
                 }
                 .cls-14 {
                     clip-path: url(#clip-path-8);
                 }
                 .cls-15 {
                     fill: url(#linear-gradient-3);
                 }
                 .cls-16 {
                     fill: #26ee4c;
                 }
                 .cls-17 {
                     stroke: #fff;
                     stroke-width: 21.9px;
                 }
                 .cls-18 {
                     fill: #fff;
                 }
                 .cls-19 {
                     fill: #751aff;
                 }
                 .cls-20 {
                     stroke: #751aff;
                     stroke-width: 21.63px;
                 }`}
              </style>
              <clipPath id="clip-path" transform="translate(-122.74 -147.39)">
                <rect
                  className="cls-1"
                  x="-7.43"
                  y="147.39"
                  width="1460"
                  height="1007.2"
                />
              </clipPath>
              <clipPath id="clip-path-2" transform="translate(-122.74 -147.39)">
                <rect
                  className="cls-1"
                  x="-7.43"
                  y="147.39"
                  width="1460"
                  height="1007.2"
                />
              </clipPath>
              <clipPath id="clip-path-3" transform="translate(-122.74 -147.39)">
                <rect
                  className="cls-1"
                  x="235.35"
                  y="191.15"
                  width="974.44"
                  height="964.44"
                />
              </clipPath>
              <clipPath id="clip-path-4" transform="translate(-122.74 -147.39)">
                <path
                  className="cls-1"
                  d="M722.57,1154.59c264.11,0,478.21-214.1,478.21-478.22S986.68,198.15,722.57,198.15,244.35,412.26,244.35,676.37s214.1,478.22,478.22,478.22"
                />
              </clipPath>
              <linearGradient
                id="linear-gradient"
                x1="152.83"
                y1="1111.34"
                x2="153.83"
                y2="1111.34"
                gradientTransform="matrix(956.44, 0, 0, -956.44, -146052.71, 1063458.9)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#b9f" />
                <stop offset="0.17" stopColor="#b9f" />
                <stop offset="0.2" stopColor="#b699ff" />
                <stop offset="0.39" stopColor="#a199ff" />
                <stop offset="0.51" stopColor="#99f" />
                <stop offset="0.62" stopColor="#a1a1ff" />
                <stop offset="0.78" stopColor="#b6b6ff" />
                <stop offset="0.91" stopColor="#ccf" />
                <stop offset="1" stopColor="#ccf" />
              </linearGradient>
              <clipPath id="clip-path-5" transform="translate(-122.74 -147.39)">
                <rect
                  className="cls-1"
                  x="489.84"
                  y="453.32"
                  width="450.1"
                  height="440.1"
                />
              </clipPath>
              <clipPath id="clip-path-6" transform="translate(-122.74 -147.39)">
                <path
                  className="cls-1"
                  d="M714.89,892.42c119.33,0,216.06-96.72,216.06-216s-96.73-216-216.06-216-216,96.73-216,216.05,96.73,216,216.05,216"
                />
              </clipPath>
              <linearGradient
                id="linear-gradient-2"
                x1="152.63"
                y1="1111.29"
                x2="153.63"
                y2="1111.29"
                gradientTransform="matrix(432.1, 0, 0, -432.1, -65575.14, 480719.58)"
                xlinkHref="#linear-gradient"
              />
              <clipPath id="clip-path-7" transform="translate(-122.74 -147.39)">
                <rect
                  className="cls-1"
                  x="343.04"
                  y="298.85"
                  width="759.05"
                  height="749.05"
                />
              </clipPath>
              <clipPath id="clip-path-8" transform="translate(-122.74 -147.39)">
                <path
                  className="cls-1"
                  d="M722.57,1046.9c204.63,0,370.52-165.89,370.52-370.53S927.2,305.85,722.57,305.85,352,471.74,352,676.37,517.93,1046.9,722.57,1046.9"
                />
              </clipPath>
              <linearGradient
                id="linear-gradient-3"
                x1="152.78"
                y1="1111.33"
                x2="153.78"
                y2="1111.33"
                gradientTransform="matrix(741.05, 0, 0, -741.05, -112990.84, 824080.22)"
                xlinkHref="#linear-gradient"
              />
            </defs>
            <g className="cls-2 outer-layer">
              <g id="Layer_1" data-name="Layer 1">
                <g className="cls-3">
                  <g className="cls-4">
                    <g className="cls-5 outer-circle" ref={outerCircle}>
                      <g className="cls-6">
                        <g className="cls-7">
                          <rect
                            className="cls-8"
                            x="121.61"
                            y="50.76"
                            width="956.44"
                            height="956.44"
                          />
                        </g>
                      </g>
                    </g>
                    <g className="cls-9 inner-circle" ref={innerCircle}>
                      <g className="cls-10">
                        <g className="cls-11">
                          <rect
                            className="cls-12"
                            x="376.11"
                            y="312.93"
                            width="432.1"
                            height="432.1"
                          />
                        </g>
                      </g>
                    </g>
                    <g className="cls-9 middle-circle" ref={middleCircle}>
                      <g className="cls-13">
                        <g className="cls-14">
                          <rect
                            className="cls-15"
                            x="229.3"
                            y="158.45"
                            width="741.05"
                            height="741.05"
                          />
                        </g>
                      </g>
                    </g>
                    <path
                      className="cls-18 white-arrow"
                      d="M580.1,686.57V559.49a7.63,7.63,0,0,1,.55-3.07,7.42,7.42,0,0,1,1.63-2.36,7.6,7.6,0,0,1,2.35-1.63,7.83,7.83,0,0,1,3.08-.54H714.78A10.54,10.54,0,0,1,724.92,562a10.21,10.21,0,0,1-10.32,10.32h-114v114a9.39,9.39,0,0,1-3.08,7.42,10.36,10.36,0,0,1-7.06,2.72,10,10,0,0,1-7.33-3,9.55,9.55,0,0,1-3-7"
                      transform="translate(-122.74 -147.39)"
                    />
                    <path
                      className="cls-16 green-circle"
                      d="M714.89,721a44.62,44.62,0,1,0-44.62-44.62A44.62,44.62,0,0,0,714.89,721"
                      transform="translate(-122.74 -147.39)"
                    />
                    <circle
                      className="cls-17 white-circle"
                      ref={whiteCircle}
                      cx="714.89"
                      cy="676.37"
                      r="44.62"
                      transform="matrix(0, -1, 1, 0, -86.02, 1242.16)"
                    />
                  </g>

                  <g className="notification-icon">
                    <rect
                      className="cls-19"
                      x="605.99"
                      y="337.82"
                      width="212.28"
                      height="212.28"
                      transform="translate(-228.08 486.19) rotate(-45)"
                    />
                    <g className="cls-4">
                      <path
                        className="cls-18"
                        d="M712.13,508.31a175.05,175.05,0,1,0-175-175,175,175,0,0,0,175,175.05"
                        transform="translate(-122.74 -147.39)"
                      />
                      <path
                        className="cls-20"
                        d="M712.13,508.31a175.05,175.05,0,1,0-175-175A175,175,0,0,0,712.13,508.31Z"
                        transform="translate(-122.74 -147.39)"
                      />
                    </g>
                    <g className="cls-4">
                      <path
                        className="cls-18 d-none"
                        d="M237.27,988.28H140.8a18.06,18.06,0,0,1-18.06-18.06V873.74a18.06,18.06,0,0,1,18.06-18.06h96.47a18.07,18.07,0,0,1,18.07,18.06v96.48a18.07,18.07,0,0,1-18.07,18.06"
                        transform="translate(-122.74 -147.39)"
                      />
                      <path
                        className="cls-19"
                        d="M686.56,259.22l-86.14,114a3.13,3.13,0,0,0,2.49,5h38.7A3.12,3.12,0,0,0,644.1,377l68.84-90.8.79-1.05.16.2L739.23,319h-.08l29.57,39.31L782.34,377a3.13,3.13,0,0,0,2.52,1.29h39.19a3.13,3.13,0,0,0,2.5-5L741,259.23a3.09,3.09,0,0,0-2.49-1.25H689.05a3.12,3.12,0,0,0-2.49,1.24"
                        transform="translate(-122.74 -147.39)"
                      />
                      <path
                        className="cls-19"
                        d="M700.25,333.18l-32.39,42.94a1.33,1.33,0,0,0,1.07,2.13h17.8a1.32,1.32,0,0,0,1.06-.53l25.31-33.4.36-.48h0l.07.09,11.52,15.27h0l13.44,17.86.89,1.19H758a1.33,1.33,0,0,0,1.07-2.13l-32.39-42.94a4.28,4.28,0,0,0-3.41-1.7H703.66a4.28,4.28,0,0,0-3.41,1.7"
                        transform="translate(-122.74 -147.39)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <div className="msg-wrapper" ref={msgWrapper}>
            <div className="img-wrapper">
              <img
                src={animationImage}
                alt="Zahn"
                className="logo-img"
                width="254"
                height="135"
              />
            </div>
            <div className="msg-content">
              <p className="title">ZAHN</p>
              <p className="subtitle" ref={subtitle}>
                Coming to you soon!
              </p>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
